import style from './Form.module.css'
import classNames from 'classnames/bind'
import React, { useState, useLayoutEffect, useContext, useEffect } from 'react'
import { Swipeable } from '../helpers/swipeable'
import Router from 'next/router'
import { event, pageview } from '../helpers/analytics'
import useTranslation from 'next-translate/useTranslation'

export const FormStepContext = React.createContext('')

export default function Form ({ title, step = '', tab = '', value, children, onClose, onForward, onBack, label }) {
  const { t } = useTranslation('main')
  const c = classNames.bind(style)
  const [swipingDown, setSwipingDown] = useState(false)

  if (typeof window !== 'undefined') {
    useLayoutEffect(() => {
      const handleKeyPress = function (e) {
        if (e.key === 'Escape') {
          typeof onBack === 'function' ? onBack() : handleClose()
        }
      }
      document.addEventListener('keydown', handleKeyPress)
      document.documentElement.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
      const bodyElem = document.getElementById('formBody')
      const stepElem = document.querySelector('#formStep_' + step + ' > section')
      const height = stepElem?.scrollHeight - (step === 'success' ? 300 : 0)
      bodyElem.style.transitionDelay = step === 'success' ? '2s' : '0s'
      bodyElem.style.flexBasis = stepElem ? height + 'px' : 'unset'
      if (tab && !window.location.pathname.endsWith('/' + tab)) {
        setTimeout(() => {
          const path = window.location.pathname.substr(0, window.location.pathname.lastIndexOf('/'))
          window.history.replaceState(window.history.state, '', path + '/' + tab)
          pageview(path + '/' + tab)
        }, 50)
      }
      return () => document.removeEventListener('keydown', handleKeyPress)
    }, [step, tab])

    useEffect(() => {
      event({ action: 'formStep', category: tab, label: step, value: value })
    }, [step])
  }

  const handleClose = e => {
    if (e?.currentTarget?.className === c('form') && e.target.className !== e.currentTarget.className) {
      return
    }
    setSwipingDown(true)
    setTimeout(onClose || Router.back, 140)
  }

  const handleSwipeClose = e => {
    if (document.getElementById('formStep_' + step)?.scrollTop <= 0) {
      handleClose(e)
    }
  }

  return (
    <div className={c('form', { swipingDown })} onClick={handleSwipeClose}>
      <Swipeable onSwipedRight={onBack} onSwipedLeft={onForward} onSwipedDown={handleSwipeClose} className={c('page')}>
        <header className={c('header')}>
          {title && <h2>{title}</h2>}
          <button
            className={'light ' + c({ close: !!onClose, back: !!onBack })}
            onClick={onBack || handleClose}
            title={onBack ? t('form-backbutton-title') : t('form-closebutton-title')}
          >
            <svg xmlns='http://www.w3.org/2000/svg' fill='#bca36c' version='1.1' viewBox='0 0 32 32'>
              <path className={c('top')} d='M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z' />
              <path className={c('bottom')} d='M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2 S29.104,22,28,22z' />
            </svg>
          </button>
          {label && <div className={c('label')}>{label}</div>}
        </header>
        <div id='formBody' className={c('body')}>
          <FormStepContext.Provider value={step}>
            {children}
          </FormStepContext.Provider>
        </div>
      </Swipeable>
    </div>
  )
}

export function Step ({ name = '', children }) {
  const c = classNames.bind(style)
  const step = useContext(FormStepContext)

  return (
    <div id={'formStep_' + name} className={c('stepOuter', { active: name === step })}>
      <section className={c('step')}>
        {children}
      </section>
    </div>
  )
}

export const getForm = page => <Form>{page}</Form>
