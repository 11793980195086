import style from './Layout.module.css'

import Nav from './Nav'
import Head from 'next/head'
import classNames from 'classnames/bind'
import useTranslation from 'next-translate/useTranslation'
import { createContext, useState, useEffect } from 'react'
import Router, { useRouter } from 'next/router'
import Notification from '../Notification'
import Link from 'next/link'
import useLocalStorageState from 'use-local-storage-state'
import { defaultCurrencies, defaultSpotType } from '../../hooks/usePrices'
import { defaultProductUnits } from '../../helpers/resolveProductUnit'
import { defaultChartScale } from '../../helpers/renderChart'
import Support from '../Support'
import LangHeaders from '../LangHeaders'

export const LayoutContext = createContext([{}, () => {}])

let timeout

export default function Layout ({ children, ...props }) {
  const { t } = useTranslation('main')
  const [menuVisible, setMenuVisible] = useState(false)
  const [uiSettings, setUISettings] = useLocalStorageState('uisettings_v2', {
    defaultValue: {
      productUnit: defaultProductUnits(props.ipCountry),
      currency: defaultCurrencies(props.ipCountry),
      chartScale: defaultChartScale,
      spotType: defaultSpotType
    }
  })
  const [layoutContext, setLC] = useState(uiSettings)
  const setLayoutContext = context => setLC(Object.assign({}, layoutContext, context))
  const [backgroundVisible, setBackgroundVisible] = useState(false)
  const router = useRouter()
  const c = classNames.bind(style)

  useEffect(() => {
    const handleRouteChange = () => {
      document.documentElement.scrollTo(0, 0)
    }
    Router.events.on('routeChangeComplete', handleRouteChange)
    return () => Router.events.off('routeChangeComplete', handleRouteChange)
  }, [])

  useEffect(() => {
    const handleScroll = () => {
      const top = document.documentElement.scrollTop || document.body.scrollTop
      setBackgroundVisible(top > 0)
    }
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  const message = layoutContext.message || layoutContext.success
  const hideMessage = () => setLayoutContext({ message: '', success: '' })

  useEffect(() => {
    clearTimeout(timeout)
    if (message) {
      timeout = setTimeout(hideMessage, layoutContext.timeout || 5000)
    }
  }, [message])

  const { productUnit, currency, chartScale, spotType } = layoutContext

  useEffect(() => {
    setUISettings({ productUnit, currency, chartScale, spotType })
  }, [productUnit, currency, chartScale, spotType])

  return (
    <LayoutContext.Provider value={[layoutContext, setLayoutContext]}>
      <Head>
        <title>{t('layout-pagetitle')}</title>
        <LangHeaders {...router} />
      </Head>

      {process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production' && (
        <div className={c('envnote')}>
          {process.env.NEXT_PUBLIC_GSA_SHOP_URL}
        </div>
      )}

      <div className={c('sticky')}>
        <div className={c('menu', { visible: menuVisible })}>
          {router?.pathname !== '/portfolio' && (
            <Link href='/portfolio'>
              <button
                className={'light ' + c(style.accountbutton)}
                title={t('header-account-menu')}
              >
                <svg xmlns='http://www.w3.org/2000/svg' fill='#bca36c' version='1.1' viewBox='0 0 18.2 18' height='24'>
                  <path d='M15.6,16.7v-5.1c0-1.9-1.2-3.1-3.1-3.1H5.9c-1.9,0-3.1,1.2-3.1,3.1v5.1H15.6z' />
                  <path d='M9.3,1.7c-1.9,0-3.1,1.3-3.1,3s1.3,3,3.1,3s3.1-1.3,3.1-3C12.5,3,11.2,1.7,9.3,1.7z' />
                </svg>
              </button>
            </Link>
          )}

          <button
            className={'light ' + c(style.menubutton)}
            onClick={() => setMenuVisible(!menuVisible)}
            title={t('header-toggle-menu')}
          >
            <svg xmlns='http://www.w3.org/2000/svg' fill='#bca36c' version='1.1' viewBox='0 0 32 32' height='34'>
              <path className={c('top')} d='M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z' />
              <path className={c('center')} d='M28,14H4c-1.104,0-2,0.896-2,2 s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z' />
              <path className={c('bottom')} d='M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2 S29.104,22,28,22z' />
            </svg>
          </button>
          <div className={c('nav', { background: backgroundVisible })} onClick={e => { if (['A', 'H6', 'SPAN'].includes(e.target.tagName) || e.target.classList.contains(c('nav'))) { setMenuVisible(false) } }}>
            <Nav />
          </div>
        </div>
        <Support />
        <Notification type={layoutContext.success ? 'success' : (layoutContext.message ? 'warning' : '')} message={message} onHide={hideMessage} visible={!!message} />
      </div>

      {children}
    </LayoutContext.Provider>
  )
}

export const getLayout = (page, props) => <Layout {...props}>{page}</Layout>
