import style from './Updown.module.css'

import classNames from 'classnames/bind'

export default function Updown ({ number }) {
  const c = classNames.bind(style)

  return (
    <svg className={c('updown', { up: number > 0, down: number < 0 })} xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 10 7'>
      {number === 0 ? <circle cx='5' cy='3.5' r='3' /> : <path d='M10 7L5.046 0 0 7z' />}
    </svg>
  )
}
