import useSWR from 'swr'
import useAuth from './useAuth.js'
import { chartScales, defaultChartScale } from '../helpers/renderChart'

export const defaultSpotType = 'gld'
export const spotTypes = ['gld', 'sil']

export const defaultCurrency = 'SGD'
export const currencies = [
  {
    code: 'SGD',
    sign: 'S$',
    symbol: '$',
    countries: ['SG', 'MY'],
    label: 'Singapore Dollar (SGD)'
  },
  {
    code: 'USD',
    sign: 'US$',
    symbol: '$',
    countries: ['US'],
    label: 'US Dollar (USD)'
  },
  {
    code: 'EUR',
    sign: '€',
    symbol: '€',
    countries: ['DE', 'FR', 'ES', 'AT', 'IT', 'NL', 'BE', 'GR'],
    label: 'Euro (EUR)'
  },
  {
    code: 'AUD',
    sign: 'A$',
    symbol: '$',
    countries: ['AU'],
    label: 'Australian Dollar (AUD)'
  },
  {
    code: 'CAD',
    sign: 'CA$',
    symbol: '$',
    countries: ['CA'],
    label: 'Canadian Dollar (CAD)'
  },
  {
    code: 'CHF',
    sign: 'Fr',
    symbol: 'fr',
    countries: ['CH'],
    label: 'Swiss Franc (CHF)'
  },
  {
    code: 'GBP',
    sign: '£',
    symbol: '£',
    countries: ['UK'],
    label: 'British Pound (GBP)'
  },
  {
    code: 'HKD',
    sign: 'HK$',
    symbol: '$',
    countries: ['HK'],
    label: 'Hong-Kong Dollar (HKD)'
  }
]
export const defaultCurrencies = countryIso => currencies.find(c => c.countries.includes(countryIso?.toUpperCase()))?.code || defaultCurrency

export function pricesFilter (prices, scale) {
  const now = new Date()
  const nowSeconds = Math.floor(now.getTime() / 1000) - now.getTimezoneOffset() * 60
  const scaleSeconds = (chartScales.find(c => c.name === scale) || chartScales.find(c => c.name === defaultChartScale)).seconds
  return prices.filter(p => nowSeconds - p.time < scaleSeconds)
}

export function priceMapper (item, resolution = 1) {
  return item.error ? {} : {
    value: item.ask * resolution,
    time: Math.floor(new Date(item.time).getTime() / 1000) - new Date().getTimezoneOffset() * 60
  }
}

export function getPriceEndPoint (currency, spotType, chartScale, date) {
  const { interval, datapoints, steps } = chartScales.find(c => c.name === chartScale) || chartScales.find(c => c.name === defaultChartScale)
  date = date ? `&toDate=${new Date(date).toISOString()}` : ''
  return currency ? `/api/gb-savings/spot-rate/interval?qtyIntervals=${datapoints + 1}&interval=${interval}&intervalSize=${steps}&spotType=${spotType}&currency=${currency}${date}` : null
}

export function usePriceHistory (currency, spotType, chartScale, productPriceResolution) {
  const { data, error } = useSWR([getPriceEndPoint(currency, spotType, chartScale), false], {
    refreshInterval: 60000,
    dedupingInterval: 60000
  })

  return {
    prices: (!data?.spots?.length ? [] : data.spots).map(m => priceMapper(m, productPriceResolution)).reverse(),
    isLoading: !error && !data,
    isError: error
  }
}

export function useLivePrice (currency, spotType = 'gld', productPriceResolution = 1) {
  const { isAuthed, authToken } = useAuth()
  const { data, error, mutate } = useSWR([currency ? `/api/gb-savings/spot-rate/interval?qtyIntervals=2&interval=minute&intervalSize=1&spotType=${spotType}&currency=${currency}` : false, isAuthed ? authToken : false], {
    refreshInterval: 10000,
    dedupingInterval: 10000
  })
  let price = {}
  let updown = 0

  if (data?.spots?.length >= 2) {
    price = priceMapper(data.spots[0], productPriceResolution)
    const lastPrice = priceMapper(data.spots[1], productPriceResolution)
    updown = price.value - lastPrice.value
  }

  return {
    price,
    updown,
    lockInPeriod: data?.variables?.lockTokenExpiry || 30,
    products: data?.products || { AuGrams: {}, AgGrams: {} },
    maxUnfundedAmount: data?.variables?.maxUnfundedAmount,
    maxTransactionAmount: data?.variables?.maxTransactionAmount,
    maxWithdrawalAmount: data?.variables?.maxWithdrawalAmount,
    maxDepositAmount: data?.variables?.maxDepositAmount,
    fx: data?.variables?.fxToBase,
    spotFx: data?.spots?.[0]?.fx,
    currency,
    isLoading: !error && !data,
    isError: error,
    resetPrice: () => mutate(() => {})
  }
}
