import style from './Notification.module.css'

import classNames from 'classnames/bind'
import { Swipeable } from '../helpers/swipeable'
// import useTranslation from 'next-translate/useTranslation'

export default function Notification ({ message, type, onHide, visible }) {
  // const { t } = useTranslation('main')
  const c = classNames.bind(style)

  return (
    <div className={c('container')}>
      <Swipeable onClick={onHide} onSwipedUp={onHide} className={c('notification', { visible: visible })}>
        <div className={c('icon', { success: type === 'success', warning: type === 'warning' })} />
        <div className={c('message')}>
          {message}
        </div>
      </Swipeable>
    </div>
  )
}
