import useSWR from 'swr'
import useAuth from './useAuth.js'

export default function useUser () {
  const { isAuthed, authToken, authedFetch } = useAuth()
  const { data, error, mutate } = useSWR(isAuthed ? ['/api/user', authToken] : null)

  const updateUser = async (user, account, revalidate = true) => {
    const body = {
      User: Object.assign({}, data?.User || {}, user),
      Account: Object.assign({}, data?.Account || {}, account)
    }
    const response = await authedFetch('/api/user', {
      method: 'POST',
      body: JSON.stringify(body)
    })
    mutate(body, revalidate)
    return response
  }

  const mutateUser = (userPartial, revalidate = false) => {
    mutate({
      Account: data.Account,
      User: Object.assign(data.User, userPartial)
    }, revalidate)
  }

  if (data?.User) {
    // const now = new Date().getTime()
    // const soon = now + (60000 * 60 * 24 * 30)
    // const expiry = new Date(data.User?.PassportExpiry || new Date()).getTime()
    data.User.IsExpiringSoon = !data.User?.UserHasUploadedAnId // || (data.User?.PassportExpiry ? expiry <= soon : !data.User?.IdentityNr)
    data.User.IsExpired = !data.User?.UserHasUploadedAnId // || (data.User?.PassportExpiry ? expiry <= now : !data.User?.IdentityNr)

    const emails = data.User.Contacts.filter(c => c.Type === 'Email')
    const defaultEmail = emails.find(c => c.IsDefault) || emails[0]
    data.User.HasVerifiedEmail = defaultEmail?.Confirmed

    const address = data.Account?.Addresses?.[0] || {}
    data.Account.HasAddress = !!(address.Street1 && address.PostalCode && address.Country)
    data.Account.HasBank = !!data.Account?.Treasuries?.find(t => t.Type === 'Bank' && t.AccNr)
  }

  return { user: data?.User, account: data?.Account, isLoading: !data && !error, updateUser, mutateUser }
}
