const createChart = typeof window !== 'undefined' && require('lightweight-charts').createChart

export const defaultChartScale = 'W'

export const chartScales = [
  {
    name: 'H',
    interval: 'minute',
    datapoints: 60,
    steps: 1,
    label: '1H',
    seconds: 60 * 60
  },
  {
    name: 'D',
    interval: 'minute',
    datapoints: 24 * 6,
    steps: 10,
    label: '24H',
    seconds: 60 * 60 * 24 + 60 * 10
  },
  {
    name: 'W',
    interval: 'hour',
    datapoints: 24 * 7,
    steps: 1,
    label: '7D',
    seconds: 60 * 60 * 24 * 7 + 60 * 60
  },
  {
    name: 'M',
    interval: 'hour',
    datapoints: 6 * 30,
    steps: 4,
    label: '30D',
    seconds: 60 * 60 * 24 * 30 + 60 * 60 * 4
  },
  {
    name: 'Y',
    interval: 'day',
    datapoints: 183,
    steps: 2,
    label: '1Y',
    seconds: 60 * 60 * 24 * 365 + 60 * 60 * 24 * 2
  }
]

export default function renderChart (elem, locale, currency, theme = 'AuGrams') {
  if (!elem || !createChart) {
    return
  }
  const chart = createChart(elem, {
    localization: {
      locale: locale,
      priceFormatter: price => price ? price.toLocaleString(locale, { style: 'currency', currency: currency, currencyDisplay: 'symbol', minimumFractionDigits: 2, maximumFractionDigits: 2 }) : ''
    },
    priceScale: {
      borderVisible: false
    },
    timeScale: {
      borderVisible: false,
      timeVisible: true,
      secondsVisible: false,
      fixLeftEdge: true,
      lockVisibleTimeRangeOnResize: true
    },
    layout: {
      fontFamily: '"Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", system-ui, sans-serif',
      backgroundColor: 'rgb(37, 38, 39)',
      textColor: 'rgba(255, 255, 255, .55)'
    },
    crosshair: {
      color: '#758696'
    },
    grid: {
      vertLines: {
        color: 'transparent'
      },
      horzLines: {
        color: 'transparent'
      }
    },
    handleScroll: false,
    handleScale: false
  })

  const themes = {
    cash: {
      topColor: 'rgba(125, 125, 125, 1)',
      bottomColor: 'rgba(95, 95, 95, 1)',
      lineColor: 'rgba(125, 125, 125, 1)',
      lineWidth: 0,
      lastValueVisible: false
    },
    sil: {
      topColor: 'rgba(200, 200, 200, 0.2)',
      bottomColor: 'rgba(200, 200, 200, 0)',
      lineColor: 'rgba(200, 200, 200, 1)',
      lineWidth: 2
    },
    AgGrams: {
      topColor: 'rgba(200, 200, 200, 1)',
      bottomColor: 'rgba(182, 182, 182, 1)',
      lineColor: 'rgba(200, 200, 200, 1)',
      lineWidth: 0,
      lastValueVisible: false
    },
    gld: {
      topColor: 'rgba(246, 195, 88, 0.3)',
      bottomColor: 'rgba(246, 195, 88, 0)',
      lineColor: 'rgba(246, 195, 88, 1)',
      lineWidth: 2
    },
    AuGrams: {
      topColor: 'rgba(246, 195, 88, 1)',
      bottomColor: 'rgba(219, 175, 79, 1)',
      lineColor: 'rgba(246, 195, 88, 1)',
      lineWidth: 0,
      lastValueVisible: false
    },
    zero: {
      topColor: 'transparent',
      bottomColor: 'transparent',
      lineColor: '#525358',
      lineWidth: 1
    }
  }

  const zeroSeries = chart.addAreaSeries(themes.zero)

  const goldSeries = chart.addAreaSeries(themes[theme])

  const silverSeries = chart.addAreaSeries(themes.AgGrams)

  const cashSeries = chart.addAreaSeries(themes.cash)

  return [chart, goldSeries, silverSeries, cashSeries, zeroSeries]
}
