import style from '../styles/form.module.css'

import { getLayout, LayoutContext } from './sg/Layout'
import Form, { Step } from './Form'
import useTranslation from 'next-translate/useTranslation'
import classNames from 'classnames/bind'
import { useState, useContext, useEffect, useRef } from 'react'
import Router from 'next/router'
import useAuth from '../hooks/useAuth'
import useWebAuth from '../hooks/useWebAuth'

let token
let provider = 'Phone Code'

export default function Login () {
  const loginFormElem = useRef()
  const recoverFormElem = useRef()
  const twofaFormElem = useRef()
  const usernameElem = useRef()
  const passwordElem = useRef()
  const twofaCodeElem = useRef()
  const recoverElem = useRef()
  const { t } = useTranslation('main')
  const c = classNames.bind(style)
  const { setAuth, setTwoFa, authedFetch } = useAuth()
  const { webAuthEnabled, webAuthName, authenticate } = useWebAuth()
  const [webAuthLogin, setWebAuthLogin] = useState(webAuthEnabled)

  // eslint-disable-next-line no-unused-vars
  const [layoutContext, setLayoutContext] = useContext(LayoutContext)
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [currentStep, setCurrentStep] = useState('login')

  // Always logout
  useEffect(() => { setAuth({}) }, [])

  useEffect(() => {
    setTimeout(() => {
      const elem = currentStep === 'login' ? usernameElem.current : (
        currentStep === '2fa' ? twofaCodeElem.current : recoverElem.current
      )
      if (elem) {
        elem.focus()
      }
    }, 400)
  }, [currentStep])

  const handleLoginSubmit = async e => {
    e.preventDefault()
    loginFormElem.current.removeAttribute('novalidate')
    if (loginFormElem.current.reportValidity()) {
      setLayoutContext({})
      setCurrentStep('loading')
      const response = await window.fetch('/api/login', {
        method: 'POST',
        mode: 'same-origin',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'error',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify({
          username: usernameElem.current.value.trim(),
          password: passwordElem.current.value.trim()
        })
      })
      const json = await response.json()
      if (json.result === 'not-qualified') {
        setCurrentStep('login')
        setLayoutContext({ message: t('login-not-qualified') })
        usernameElem.current.setCustomValidity(t('login-username-notqualified'))
        setTimeout(() => loginFormElem.current.reportValidity(), 250)
        setTimeout(() => (window.location = 'https://www.silverbullion.com.sg/ContactUs'), 6000)
      } else if (json.result === '2fa-required') {
        token = json.token
        provider = json.twoFaProvider
        setCurrentStep('2fa')
      } else if (!setAuth(json)) {
        setCurrentStep('login')
        if (response.status !== 500) {
          passwordElem.current.setCustomValidity(t('login-invalidlogin'))
          usernameElem.current.setCustomValidity(t('login-invalidlogin'))
          setTimeout(() => loginFormElem.current.reportValidity(), 250)
        } else {
          setLayoutContext({ message: t('login-unavailable') })
        }
      } else {
        setCurrentStep('2fa')
      }
    }
  }

  const handlePasswordVisibility = e => {
    e.preventDefault()
    setPasswordVisible(!passwordVisible)
  }

  const handle2faSubmit = async e => {
    e.preventDefault()
    twofaCodeElem.current.setCustomValidity('')
    twofaFormElem.current.removeAttribute('novalidate')
    if (twofaFormElem.current.reportValidity()) {
      setLayoutContext({})
      setCurrentStep('loading')
      if (token) {
        const response = await window.fetch('/api/2fa', {
          method: 'POST',
          mode: 'same-origin',
          cache: 'no-cache',
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json'
          },
          redirect: 'error',
          referrerPolicy: 'no-referrer',
          body: JSON.stringify({
            token,
            twofa: twofaCodeElem.current.value.trim()
          })
        })
        const json = await response.json()
        if (json.changeUser) {
          Router.push('/username')
          setAuth(json)
        } else if (!setAuth(json)) {
          setCurrentStep('2fa')
          twofaCodeElem.current.setCustomValidity(t('login-2faerror'))
          setTimeout(() => twofaFormElem.current.reportValidity(), 250)
        }
      } else {
        const response = await authedFetch('/api/user/2fa/phone', {
          method: 'POST',
          cache: 'no-cache',
          body: JSON.stringify({
            token: twofaCodeElem.current.value.trim()
          })
        })
        if (response.ok) {
          setTwoFa(true)
        } else {
          setCurrentStep('2fa')
          twofaCodeElem.current.setCustomValidity(t('login-2faerror'))
          setTimeout(() => twofaFormElem.current.reportValidity(), 250)
        }
      }
    }
  }

  const handleRecoverSubmit = async e => {
    e.preventDefault()
    recoverFormElem.current.removeAttribute('novalidate')
    if (recoverFormElem.current.reportValidity()) {
      setLayoutContext({})
      setCurrentStep('loading')
      await window.fetch('/api/user/request-password-reset', {
        method: 'POST',
        mode: 'same-origin',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'error',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify({
          e: recoverElem.current.value.trim()
        })
      })
      setTimeout(() => {
        setCurrentStep('login')
        setLayoutContext({
          success: t('login-recoveryemailrequested')
        })
      }, 1000)
    }
  }

  const handle2faResend = async (phone, error = '') => {
    phone = window.prompt(error + t('login-resendphone2fasetuptoken'), phone)
    if (phone !== null) {
      const response = await authedFetch('/api/user/2fa/phone/send', {
        method: 'POST',
        cache: 'no-cache',
        body: JSON.stringify({
          Phone: phone.trim()
        })
      })
      if (!response.ok) {
        handle2faResend(phone, t('login-resendphone2fasetuptokenerror') + ' ')
      }
    }
  }

  const isPWA = typeof window !== 'undefined' && window.matchMedia('(display-mode: standalone)').matches

  return (
    <section className={c('login')}>
      <Form
        title={t('login-title')}
        onClose={currentStep === 'login' ? () => Router.push('/sg') : undefined}
        onBack={!['login', 'loading'].includes(currentStep) ? () => { setLayoutContext({}); setCurrentStep('login') } : undefined}
        step={currentStep}
      >
        <Step name='login'>
          {webAuthLogin ? (
            <>
              <h3>{t('login-webauth-title', { displayName: webAuthName })}</h3>
              <br />
              <button onClick={authenticate}>{t('login-webauth-loginbutton')}</button>
              <br /><br /><br />
              <a href='#' onClick={e => { e.preventDefault(); setWebAuthLogin(false) }}>{t('login-webauth-returntologin')}</a>
            </>
          ) : (
            <>
              <form noValidate ref={loginFormElem} id='loginform' onSubmit={handleLoginSubmit} className={c('login')}>
                {!isPWA &&
                  <>
                    <div className={c('verifyurl')}>
                      <svg xmlns='http://www.w3.org/2000/svg' width='10' height='12' viewBox='0 0 10 12' fill='none'>
                        <path d='M8.94504 5.62303C9.79904 5.77603 9.92904 5.93103 9.93004 6.75603C9.93004 8.08503 9.93004 9.41402 9.93004 10.743C9.93004 11.421 9.67604 11.668 8.99404 11.668C6.63604 11.668 4.27838 11.668 1.92104 11.668C1.20704 11.668 0.961043 11.429 0.959043 10.726C0.95571 9.38269 0.95571 8.03936 0.959043 6.69603C0.959043 5.93503 1.13304 5.74203 1.94404 5.64103C1.94404 5.15503 1.93204 4.65903 1.94404 4.16403C1.94681 3.57581 2.09813 2.99783 2.38395 2.48371C2.66976 1.9696 3.08082 1.53601 3.57897 1.2232C4.07712 0.910385 4.64622 0.728477 5.23345 0.694357C5.82068 0.660237 6.40702 0.775008 6.93804 1.02802C7.54096 1.30342 8.05092 1.74799 8.40597 2.30771C8.76101 2.86744 8.94586 3.51823 8.93805 4.18102C8.95704 4.66402 8.94504 5.14803 8.94504 5.62303ZM3.47904 5.63902H7.42404C7.42404 5.06702 7.44704 4.52502 7.42404 3.98602C7.37948 3.51025 7.16487 3.0665 6.81958 2.73617C6.47428 2.40584 6.02147 2.21108 5.54419 2.18763C5.06691 2.16417 4.59717 2.31359 4.22115 2.60847C3.84514 2.90335 3.58804 3.32392 3.49704 3.79303C3.45481 4.40747 3.4488 5.02387 3.47904 5.63902Z' fill='#093' />
                      </svg>
                      <span>https:</span>
                      <span>//www.stargrams.app</span>
                    </div>
                    <p className={c('hint')}>{t('login-verifyurl')}</p>
                  </>}
                <div className={c('inputfield')}>
                  <input type='text' ref={usernameElem} id='username' autoComplete='username' required minLength={5} maxLength={20} pattern='^[A-Za-z0-9@_\-$]+$' placeholder={t('login-username-placeholder')} onChange={e => { e.target.setCustomValidity(''); passwordElem.current.setCustomValidity('') }} />
                </div>
                <div className={c('inputfield')}>
                  <input type={passwordVisible ? 'text' : 'password'} ref={passwordElem} autoComplete='current-password' required minLength={8} id='password' placeholder={t('login-password-placeholder')} onChange={e => { e.target.setCustomValidity(''); usernameElem.current.setCustomValidity('') }} />
                  <button type='button' className={c('revealpassword') + ' light'} onClick={handlePasswordVisibility}>
                    {!passwordVisible && <img src='/images/reveal.svg' alt={t('login-password-reveal')} />}
                    {passwordVisible && <img src='/images/hide.svg' alt={t('login-password-hide')} />}
                  </button>
                </div>
                <button type='submit'>{t('login-loginbutton')}</button>
                <img src='/images/logo-icon-black.svg' width={29} height={29} alt='Silver Bullion' style={{ opacity: '.6', margin: '0 .5em 0 .7em', verticalAlign: '-55%' }} />
                <span style={{ display: 'inline-block', width: '140px', color: '#777', fontSize: '0.85em', verticalAlign: '-50%' }}>
                  {t('login-login-link')}
                </span>
              </form>
              <br />
              <a href='#' onClick={e => { setCurrentStep('recover'); e.preventDefault() }}>
                {t('login-password-forgot-link')}
              </a>
              <br />
              {/* <p className={c('note')}>{t('login-existingaccount-note')}</p> */}
              <br />
              <h3>{t('login-signup-title')}</h3>
              <p>{t('login-signup-tease')}</p>
              <button className='goldenshimmer' onClick={() => Router.replace('/signup')}>{t('login-signup-button')}</button>
            </>
          )}
        </Step>
        <Step name='recover'>
          <form noValidate ref={recoverFormElem} id='recoverform' onSubmit={handleRecoverSubmit} className={c('recover')}>
            <h3>{t('login-recover-title')}</h3>
            <p>{t('login-recover-info')}</p>
            <div className={c('inputfield')}>
              <input type='text' ref={recoverElem} id='recover' required minLength={3} placeholder={t('recover-input-placeholder')} />
            </div>
            <br />
            <button type='submit'>{t('login-recover-button')}</button>
          </form>
        </Step>
        <Step name='2fa'>
          <form noValidate ref={twofaFormElem} id='2faform' onSubmit={handle2faSubmit} className={c('twofa')}>
            {token ? (
              <>
                <h3>{t('login-2fa-title')}</h3>
                <p>{provider === 'Phone Code' ? t('login-2faviaphone-info') : t('login-2faviaauthenticator-info')}</p>
              </>
            ) : (
              <>
                <h3>{t('login-2faviaphonesetup-title')}</h3>
                <p>{t('login-2faviaphonesetup-info')}</p>
              </>
            )}
            <div className={c('inputfield')}>
              <input type='text' ref={twofaCodeElem} id='2facode' autoComplete='one-time-code' required minLength={6} maxLength={6} pattern='[0-9]*' inputMode='numeric' placeholder={t('login-2facode-placeholder')} onChange={e => e.target.setCustomValidity('')} />
              {!token && <div><a href='#' onClick={e => { e.preventDefault(); handle2faResend() }}>{t('login-changephonenumber-button')}</a></div>}
            </div>
            <br />
            <button type='submit'>{t('login-2fa-verifybutton')}</button>
          </form>
        </Step>
        <Step name='loading'>
          <div className={c('loading')}>
            <img src='/images/loader.svg' />
          </div>
        </Step>
      </Form>
    </section>
  )
}

Login.getLayout = getLayout
