import fetch from 'isomorphic-fetch'
// import { useEffect } from 'react'
// import useSWR from 'swr'
import useLocalStorageState from 'use-local-storage-state'

const localStorageKey = 'authentication'
const isValid = auth => !!auth?.access_token && !!auth.token_type && new Date() < new Date(auth.expiry)

export default function useAuth () {
  const [authState, setAuthState] = useLocalStorageState(localStorageKey)

  // const refreshFetcher = url => {
  //   const auth = JSON.parse(window.localStorage.getItem(localStorageKey) || '{}')
  //   const token = isValid(auth) && auth?.refresh_token
  //   if (!token) {
  //     throw new Error('Unauthorised')
  //   }
  //   return fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ token }) }).then(res => {
  //     const json = res.json()
  //     if (!res.ok) {
  //       throw new Error('Unauthorised')
  //     } else {
  //       return json
  //     }
  //   })
  // }

  // const { data, isValidating } = useSWR(authState?.refresh_token ? '/api/refresh' : null, {
  //   initialData: authState,
  //   revalidateOnMount: false,
  //   dedupingInterval: 1000,
  //   refreshInterval: 55 * 60000,
  //   focusThrottleInterval: 30 * 60000,
  //   shouldRetryOnError: false,
  //   errorRetryCount: 0,
  //   fetcher: refreshFetcher
  // })

  // useEffect(() => {
  //   // console.log('Auth data changed', data)
  //   if (!isValidating && isValid(data)) {
  //     // console.log('Auth data valid')
  //     setAuth(data)
  //   }
  // }, [data])

  const setAuth = function (auth) {
    if (typeof auth === 'object' && auth.expires_in > 0) {
      auth.expiry = auth.expiry || new Date(new Date().getTime() + (auth.expires_in * 1000)).toISOString()
      // window.localStorage.setItem(localStorageKey, JSON.stringify(auth))
      setAuthState(auth)
      return isValid(auth)
    } else {
      setAuthState({})
      return false
    }
  }

  const setTwoFa = function (twoFa) {
    setAuth(Object.assign({}, authState, { twoFa }))
    return !!twoFa
  }

  const authedFetch = function (url, useAuth = true, options) {
    if (options === undefined && typeof useAuth === 'object') {
      options = useAuth
    }
    const h = Object.assign({
      'Content-Type': 'application/json'
    }, options?.headers)
    const o = Object.assign({
      mode: 'same-origin',
      credentials: 'same-origin',
      redirect: 'error',
      referrerPolicy: 'no-referrer'
    }, options, {
      headers: h
    })
    if (useAuth) {
      const auth = JSON.parse(window.localStorage.getItem(localStorageKey) || '{}')
      if (!isValid(auth)) {
        setAuthState({})
        return new Promise(resolve => resolve(new window.Response('"Unauthorised"', { status: 401 })))
      }
      if (url?.match(/^\/api/) && auth?.token_type && auth?.access_token) {
        o.headers.authorization = auth.token_type + ' ' + auth.access_token
      }
    }
    return fetch(url, o).then(response => {
      if (!!o.headers.authorization && response.status === 401) {
        console.log('Logging out')
        setAuthState({})
      }
      return new Promise(resolve => resolve(response))
    })
  }

  return {
    isAuthed: isValid(authState),
    isTwoFaEnabled: !!authState?.twoFa,
    authToken: authState?.access_token,
    authType: authState?.token_type,
    authedFetch,
    setAuth,
    setTwoFa
  }
}
