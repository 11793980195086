import style from './Support.module.css'
import formStyle from '../styles/form.module.css'
import { useRef, useState, useContext } from 'react'
import classNames from 'classnames/bind'
import { Swipeable } from '../helpers/swipeable'
import useTranslation from 'next-translate/useTranslation'
import { LayoutContext } from './sg/Layout'
import Loading from '../components/Loading'
import useUser from '../hooks/useUser'

export default function Support () {
  const { t } = useTranslation('main')
  const c = classNames.bind(style)
  const cf = classNames.bind(formStyle)
  const [visible, setVisible] = useState()
  const [rendered, setRendered] = useState()
  const [sendStatus, setSendStatus] = useState('')
  const formElem = useRef()
  const nameElem = useRef()
  const emailElem = useRef()
  const messageElem = useRef()
  // eslint-disable-next-line no-unused-vars
  const [layoutContext, setLayoutContext] = useContext(LayoutContext)
  const { user, account } = useUser()

  const handleSupportSubmit = e => {
    e.preventDefault()
    formElem.current.removeAttribute('novalidate')
    if (!formElem.current.reportValidity()) {
      return
    }

    setSendStatus('sending')

    window.fetch('/api/contact', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name: nameElem.current.value.trim(),
        email: emailElem.current.value.trim(),
        message: messageElem.current.value.trim(),
        username: user?.UserName,
        account: account?.AccountId
      })
    }).then(res => {
      if (res.status !== 200) {
        throw new Error('API not available')
      }
      setLayoutContext({ success: t('support-form-sent') })
      setSendStatus('sent')
      handleClose()
    }).catch(e => {
      setLayoutContext({ message: t('support-form-error') })
      setSendStatus('error')
    })
  }

  const handleToggle = () => {
    if (!visible && !rendered) {
      setSendStatus('')
      setRendered(true)
      setTimeout(() => setVisible(true), 10)
    } else {
      handleClose()
    }
  }

  const handleClose = e => {
    e && e.preventDefault()
    setVisible(false)
    setTimeout(() => setRendered(false), 200)
  }

  const emails = user?.Contacts.filter(c => c.Type === 'Email') || []
  const email = emails.find(c => c.IsDefault)?.Value || emails[0]?.Value || ''

  return (
    <div className={c('container', { visible })}>
      <button onClick={handleToggle} title={visible ? t('support-support-show') : t('support-support-hide')} className={c('supportbutton')}>
        <svg width='20' height='20' viewBox='0 0 20 20' aria-hidden='true'><path d='M11,12.3V13c0,0-1.8,0-2,0v-0.6c0-0.6,0.1-1.4,0.8-2.1c0.7-0.7,1.6-1.2,1.6-2.1c0-0.9-0.7-1.4-1.4-1.4 c-1.3,0-1.4,1.4-1.5,1.7H6.6C6.6,7.1,7.2,5,10,5c2.4,0,3.4,1.6,3.4,3C13.4,10.4,11,10.8,11,12.3z' /><circle cx='10' cy='15' r='1' /><path d='M10,2c4.4,0,8,3.6,8,8s-3.6,8-8,8s-8-3.6-8-8S5.6,2,10,2 M10,0C4.5,0,0,4.5,0,10s4.5,10,10,10s10-4.5,10-10S15.5,0,10,0 L10,0z' /></svg>
        <span>{t('support-support-button')}</span>
      </button>
      <Swipeable onClick={handleClose} onSwipedDown={handleClose} className={c('notification', { visible: visible })} />
      {rendered && (
        <div className={c('popup')}>
          <form noValidate ref={formElem} id='supportform' onSubmit={handleSupportSubmit} className={c('support')}>
            <button onClick={handleClose} title={t('support-support-hide')} className={'light ' + c('closebutton')}>_</button>
            <h3>{t('support-form-title')}</h3>
            <div className={cf('inputfield')}>
              <label htmlFor='support_name'>{t('support-name-label')}</label>
              <input type='text' key={user ? user.FirstName + ' ' + user.LastName : ''} disabled={!!user} defaultValue={user ? user.FirstName + ' ' + user.LastName : ''} ref={nameElem} id='support_name' autoFocus autoComplete='name' required minLength={2} placeholder={t('support-name-placeholder')} onChange={e => { e.target.setCustomValidity('') }} />
            </div>
            <div className={cf('inputfield')}>
              <label htmlFor='support_email'>{t('support-email-label')}</label>
              <input type='email' key={email} defaultValue={email} disabled={!!email} ref={emailElem} id='support_email' autoComplete='email' required placeholder={t('support-email-placeholder')} onChange={e => { e.target.setCustomValidity('') }} />
            </div>
            <div className={cf('inputfield')}>
              <label htmlFor='support_message'>{t('support-message-label')}</label>
              <textarea ref={messageElem} id='support_email' rows={5} required placeholder={t('support-message-placeholder')} onChange={e => { e.target.setCustomValidity('') }} />
            </div>
            <button type='submit' disabled={sendStatus === 'sending'}>{sendStatus !== 'sending' ? t('support-sendmessage-button') : <Loading />}</button>
          </form>
        </div>
      )}
    </div>
  )
}
