
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    
import '../styles/global.css'
import * as Sentry from '@sentry/node'
import { Integrations } from '@sentry/tracing'
import Head from 'next/head'
import useAuth from '../hooks/useAuth'
import { SWRConfig } from 'swr'
import Login from '../components/Login'
import Router from 'next/router'
import { pageview } from '../helpers/analytics'
import useTranslation from 'next-translate/useTranslation'
// import LangHeaders from '../components/LangHeaders'

// Setup Sentry
if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NEXT_PUBLIC_VERCEL_ENV === 'production',
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.05
  })
}

// Hookup Google Analytics PageView event
Router.events.on('routeChangeComplete', (url) => pageview(url))

const __Page_Next_Translate__ = function App ({ Component, router, pageProps, err }) {
  const { isAuthed, isTwoFaEnabled, authedFetch } = useAuth()
  const lang = router.locale.split('-')[0]
  const langExtension = lang !== 'en' ? '-' + lang : ''
  const hideSSR = typeof window === 'undefined' && Component.requiresAuth

  if (Component.requiresAuth && !(isAuthed && isTwoFaEnabled)) {
    Component = Login
  }

  const getLayout = Component.getLayout || (page => page)

  const { t } = useTranslation('main')

  return (
    <>
      <SWRConfig value={{ fetcher: (...args) => authedFetch(...args).then(res => res.json()) }}>
        {getLayout((
          <>
            <Head>
              {typeof window !== 'undefined' && (document.body.offsetWidth <= 375 ? <meta key='min' name='viewport' content='width=375, viewport-fit=cover' /> : <meta key='device' name='viewport' content='width=device-width, initial-scale=1, viewport-fit=cover' />)}
              <meta property='og:title' key='og:title' content={t('layout-pagetitle')} />
              <meta property='og:url' key='og:url' content={`https://www.stargrams.app/${router.locale}/sg`} />
              <meta property='og:image' key='og:image' content={`https://www.stargrams.app/images/og_starsavingsaccount${langExtension}.png`} />
              <meta property='og:video' key='og:video' content={`https://www.stargrams.app/assets/starsavingsaccount_intro${langExtension}.mp4`} />
              <meta property='og:video:height' key='og:video:height' content='720' />
              <meta property='og:video:width' key='og:video:width' content='1280' />
              <meta property='og:video:type' key='og:video:type' content='video/mp4' />
              <meta property='og:description' key='og:description' content={t('layout-pagedescription')} />
              <meta property='og:type' key='og:type' content='website' />
              <meta property='og:site_name' key='og:site_name' content={t('layout-goldsaver')} />
              <meta name='title' key='title' content={t('layout-pagetitle')} />
              <meta name='description' key='description' content={t('layout-pagedescription')} />
              {/* <title>{t('layout-pagetitle')}</title>
              <LangHeaders {...router} /> */}
            </Head>
            {!hideSSR && <Component {...pageProps} err={err} />}
          </>
        ), pageProps)}
      </SWRConfig>
    </>
  )
}


    export default __appWithI18n(__Page_Next_Translate__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  